.newsletter {
    text-align: center;
    margin-bottom: rem(80);
    padding-bottom: rem(40);
    h2 {
        margin-bottom: rem(20);
    }
    iframe {
        margin: 0 auto;
        max-width: 800px;
    }
    p {
        font-size: rem(20);
        text-align: center;
        text-transform: uppercase;
        max-width: rem(540);
        margin: 0 auto;
    }
    @include media-breakpoint-up(md) {
        margin-bottom: rem(160);
        p {
            font-size: rem(22);
        }
    }
}