.options-intro {
    p {
        font-size: rem(20);
        text-align: center;
        @include media-breakpoint-up(lg) {
            font-size: rem(24);
        }
    }
}
.sponsoring-options {
    margin-bottom: rem(80);
    &--item {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: rem(24) rem(16);
        height: 100%;
        background-color: rgba($black, .25);
        &:before {
            content: '';
            display: block;
            padding-bottom: 160%;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
            z-index: -1;
        }
        h3 {
            color: $primary;
            text-align: center;
            line-height: 1.2;
            position: absolute;
            width: 100%;
            left: 0;
            padding: 0 rem(16);
            &:after {
                content: '';
                height: rem(1);
                width: 30%;
                background-color: $white;
                position: absolute;
                bottom: rem(-4);
                left: 35%;
            }
        }
    }
    @include media-breakpoint-only(xs) {
        &--item {
            h3 {
                font-size: rem(28);
            }
        }
    }
    @include media-breakpoint-only(md) {
        padding: 0 rem(80);
    }
    @include media-breakpoint-up(md) {
        margin-bottom: rem(160);
        &--item {
            padding: rem(32) rem(24);
            h3 {
                padding: 0 rem(24);
                &:after {
                    bottom: rem(-8);
                }
            }
        }
    }
    &.editmode {
        .sponsoring-options--item {
            overflow: auto;
            &:before {
                content: '';
                display: none;
                padding-bottom: 0;
            }
            img {
                position: relative;
            }
            h3 {
                position: relative;
            }
        }
    }
}
