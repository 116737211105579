// START: do not change those
$enable-rfs: false;
$enable-negative-margins: true;
// END: do not change those

// START: project-custom values
$primary: #B2FF00;
$secondary: #12083C;
$white: #FFFFFF;
$black: #000000;
$black-transparent-t8: rgba($black, 0.8);
$black-transparent-t5: rgba($black, 0.5);
$background: #131317 ;
$background-transparent-t9: rgba($background, 0.9);
$background_gradient-blue: rgba(#00242F, 0.8);
$background_gradient-green: rgba(#193D23, 0.8);

$container-max-widths: (
    sm: 540px,
    md: 760px,
    lg: 960px,
    xl: 1000px,
    xxl: 1024px
);

// add new theme-colors example:
$custom-theme-colors: (
    'background': $background,
    'backgroundtransparent': $background-transparent-t9,
);
// END: project-custom values
