.speaker {
    padding-bottom: 0;
    margin-bottom: 0;
    &__person {
        margin-bottom: rem(100);
        padding-bottom: rem(80);
        position: relative;
        .background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(to right, $background_gradient-green, $background_gradient-blue);
            opacity: 0.8;
            z-index: -1;
            height: 100%;
            // transition: padding-bottom 1.5s, width 0.2s;
            transition: height .5s, width 0.2s;
            will-change: height, width;
        }
        .picture {
            z-index: 1;
            padding: rem(20);
            padding-bottom: rem(20);
            display: inline-block;
            position: relative;
            img {
                width: 100%;
                display: block;
                position: relative;
                z-index: 2;
            }
        }
        .links {
            position: relative;
            display: flex;
            justify-content: center;
            z-index: 2;
            a {
                font-size: 0;
                display: block;
                background-color: transparent;
                border-radius: 50%;
                height: rem(50);
                width: rem(50);
                margin-right: rem(10);
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                transition: background-color 0.2s;
                filter: grayscale(1) contrast(2);
                &:last-of-type {
                    margin-right: 0;
                }
                &.facebook {
                    background-image: url('/assets/img/icons/icon_facebook.svg');
                }
                &.twitter {
                    background-image: url('/assets/img/icons/icon_twitter.svg');
                }
                &.instagram {
                    background-image: url('/assets/img/icons/icon_instagram.svg');
                }
                &.youtube {
                    background-image: url('/assets/img/icons/icon_youtube.svg');
                }
                &.website {
                    background-image: url('/assets/img/icons/icon_website.svg');
                }
            }
        }
        .details {
            .name {
                margin: 0;
                margin-top: rem(10);
                display: block;
                font-size: rem(50);
                word-spacing: rem(15);
                letter-spacing: rem(2);
                position: relative;
                z-index: 2;
            }
            p {
                position: relative;
                width: auto;
                z-index: 1;
            }
            &:after {
                content: 'READ MORE';
                display: inline-block;
                width: 100%;
                text-align: center;
                position: absolute;
                left: 0;
                bottom: 0;
                color: $primary;
                height: rem(46);
                line-height: rem(46);
                margin-top: rem(20);
                margin-bottom: rem(25);
                padding: 0 rem(30);
                font-family: "BenchNine", sans-serif;
                text-transform: uppercase;
                cursor: pointer;
                letter-spacing: rem(1);
            }
        }
        .content {
            max-height: 0;
            transition: max-height 0.2s, opacity 0.2s;
            opacity: 1;
            overflow: hidden;
        }
        &.active {
            padding-bottom: rem(100);
            .content {
                max-height: rem(2000);
                transition: max-height 1s, opacity 0.2s;
                opacity: 1;
                // overflow-y: hidden;
            }
            .details {
                &:after {
                    content: 'CLOSE';
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .speaker {
        &__person {
            .content {
                max-height: 0;
                transition: max-height 0.2s, opacity 0.2s;
                opacity: 0;
                padding: 0 rem(32);
                h4 {
                    margin-bottom: rem(20);
                    margin-top: rem(48);
                }
            }
            &.active {
                .background {
                    width: 100%;
                    height: 100%;
                    transition: height 0.5s, width 0.2s;
                    will-change: height, width;
                }
                .content {
                    padding-bottom: rem(48);
                    max-height: rem(1200);
                    transition: max-height 0.2s, opacity 0.2s;
                    opacity: 1;
                    overflow-y: hidden;
                }
                .picture {
                    position: static;
                    &:after {
                        content: '⌃';
                        display: block;
                        background-color: rgba($white, 0.05);
                        transition: background-color 0.2s;
                        color: $white;
                        line-height: 0.6;
                        padding-top: rem(35);
                        text-align: center;
                        width: 100%;
                        z-index: 9;
                        position: absolute;
                        font-size: 4rem;
                        bottom: 0;
                        left: 0;
                    }
                    &:hover {
                        &:after {
                            background-color: rgba($white, 0.1);
                            transition: background-color 0.2s;
                        }
                    }
                }
                .details {
                    z-index: 2;
                }
            }
            &:not(.active) {
                .picture:hover,
                .picture.hover {
                    ~.background {
                        filter: brightness(110%) saturate(1.5) hue-rotate(33deg);
                        transition: filter 0.5s;
                        will-change: filter;
                    }
                    ~.wrapper-details {
                        .details {
                            p {
                                &:after {
                                    filter: brightness(160%) hue-rotate(320deg);
                                    transition: filter 0.5s;
                                    will-change: filter;
                                }
                            }
                        }
                    }
                }
            }
            .background {
                width: 50%;
                height: 86%;
                transition: height 0.2s, width 0.2s, filter 0.3s;
                will-change: height, width;
            }
            .links {
                display: flex;
                z-index: 3;
                a {
                    width: rem(60);
                    height: rem(60);
                    &:hover {
                        transition: filter 0.2s;
                        filter: grayscale(0) contrast(1);
                    }
                }
            }
            .picture {
                cursor: pointer;
                position: static;
                // &:before {
                //     content: '';
                //     display: block;
                //     position: absolute;
                //     padding-bottom: 30%;
                //     left: 40%;
                //     right: 15%;
                //     margin-top: 13%;
                //     z-index: 3;
                // }
            }
            .wrapper-details {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: rem(-80);
                &.editmode {
                    margin-left: 0;
                    .details {
                        p {
                            &::after {
                                content: none;
                            }
                        }
                    }
                    .links {
                        display: block;
                        margin-top: rem(20);
                        a {
                            filter: none;
                            font-size: 12px;
                            border-radius: 0;
                        }
                    }
                }
            }
            .details {
                cursor: pointer;
                z-index: 2;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                .name {
                    font-size: rem(64);
                    text-align: left;
                    word-spacing: rem(1000);
                    line-height: 1.2;
                    span {
                        white-space: nowrap;
                        word-spacing: 0;
                    }
                }
                &:after {
                    display: none;
                }
                p {
                    font-size: rem(22);
                    max-width: rem(550);
                    margin: 0;
                    padding-right: rem(80);
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: rem(-40);
                        bottom: rem(-30);
                        right: rem(-30);
                        left: rem(-30);
                        z-index: -1;
                        opacity: 0.8;
                        transition: filter 0.5s;
                        filter: hue-rotate(0deg) saturate(100%);
                        will-change: filter;
                        background: $background;
                    }
                }
            }
        }
    }
}
