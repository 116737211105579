.teaser {
    margin-bottom: rem(80);
    .image-text {
        align-items: center;
        img {
            width: 100%;
        }
    }
    .teaser-text {
        h3 {
            font-size: rem(45);
            font-weight: 700;
            line-height: 1.1;
        }
        h4 {
            color: $primary;
            font-weight: 700;
            line-height: 1.1;
        }
        p {
            max-width: rem(450);
            line-height: 1.5;
        }
    }
    @include media-breakpoint-up(md) {
        margin-bottom: rem(160);
        .teaser-text {
            text-align: right;
            h3 {
                font-size: rem(50);
            }
            p {
                margin-bottom: 0;
            }
        }
        .image-text {
            &:nth-of-type(even) {
                .teaser-text {
                    order: 1;
                    text-align: left;
                }
            }
        }
    }
}
