.container-cards {
    margin-bottom: rem(120);
    .card {
        background-color: $background;
        position: relative;
        height: 100%;
        border: 0;
        transition: background-color .6s;
        .card-image {
            overflow: hidden;
            position: relative;
            &::before {
                content: '';
                display: block;
                padding-bottom: 50%;
            }
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform: translate(-50%, -50%);
            }
        }
        h4 {
            color: $primary;
        }
        .stretched-link {
            &:hover {
                svg {
                    transform: translateX(4px);
                    transition: .6s transform;
                }
            }
        }
        &:hover {
           background-color: $background-transparent-t9;
        }
        &.editmode {
            .card-image {
                &::before {
                    padding-bottom: 0;
                }
                img {
                    position: relative;
                    top: 0;
                    left: 0;
                    transform: translate(0);
                }
            }
            .stretched-link {
                &:after {
                    position: relative;
                }
            }
        }
    }
}
