.video--keyvisual {
    margin-bottom: rem(80);
    .responsive-video {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 0;
        height: auto;
        overflow: hidden;
        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            &::-webkit-media-controls-start-playback-button {
                display: none;
            }
        }
        .playpause {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $black-transparent-t5;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-size: contain;
            background-position: center;
            padding: rem(10);
            transition: opacity 1s;
            h2 {
                font-size: rem(24);
            }
        }
        &.playing {
            .playpause {
                opacity: 0;
                transition: opacity .4s;
            }
        }
    }
    @include media-breakpoint-up(md) {
        margin-top: rem(-100);
        margin-bottom: rem(160);
        .responsive-video {
            .playpause {
                cursor: pointer;
                h2 {
                    font-size: rem(80);
                    margin-bottom: rem(10);
                }
            }
        }
    }
    &.editmode {
        margin-top: 0;
        .responsive-video {
            video {
                position: relative;
            }
        }
        .playpause {
            position: relative;
            width: auto;
            height: auto;
            opacity: 1 !important;
        }
    }
}