.sponsors {
    margin-bottom: rem(80);
    p {
        font-size: rem(20);
    }
    h3 {
        font-size: rem(32);
        margin: rem(40) auto;
    }
    .spacer-line {
        width: 100%;
        height: 2px;
        background-color: $white;
    }
}
.logo-grid {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    &:not(.editmode) {
        a {
            height: rem(56);
            display: block;
            margin: 0 rem(24) rem(48) rem(24);
            img {
                height: 100%;
                width: auto;
                max-width: 100%;
            }
            .scale {
                &__60 {
                    height: 60%;
                }
                &__90 {
                    height: 90%;
                }
                &__120 {
                    height: 120%;
                }
                &__160 {
                    height: 160%;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .sponsors {
        margin-bottom: rem(160);
        p {
            font-size: rem(24);
        }
        h3 {
            font-size: rem(53);
            margin: rem(53) auto;
        }
    }
    .logo-grid {
        &:not(.editmode) {
            a {
                height: rem(70);
                margin: 0 rem(32) rem(48) rem(32);
            }
        }
        &.editmode {
            img {
                width: 200px;
            }
            .pimcore_editable_block {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
}
