.text-block {
    margin-bottom: rem(80);
    margin-top: rem(80);
    padding-bottom: rem(80);
    &__content {
        padding: 0 rem(24);
        .subheadline {
            text-align: center;
            &:not(:empty) {
                margin-bottom: rem(32);
                margin-top: rem(32);
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .text-block {
        margin-bottom: rem(160);
        &__content {
            padding: 0 rem(48);
        }
    }
}
