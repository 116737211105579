$iconSizes: 8, 16, 24, 32, 40, 48, 56, 64, 80, 120;
$iconRotations: 45, 90, 135, 180, 225, 270, 315;

svg {
    &[size] {
        flex-shrink: 0;
    }

    @each $iconSize in $iconSizes {
        &[size="#{$iconSize}"] {
            width: rem($iconSize);
            height: rem($iconSize);
        }
    }

    @each $iconRotation in $iconRotations {
        &[rotate="#{$iconRotation}"] {
            transform: rotate(#{$iconRotation}deg);
        }
    }
}
