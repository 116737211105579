header {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    height: rem(50);
    .topbar {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $black-transparent-t8;
        padding: 0 rem(25);
        &__logo {
            height: rem(35);
            img {
                display: block;
                height: 95%;
            }
        }
        button {
            background-color: transparent;
            border: 0;
        }
    }
}

@include media-breakpoint-up(lg) {
    header {
        height: rem(100);
        .topbar {
            display: none;
        }
    }
}
