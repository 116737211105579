.speaker-grid {
    margin-bottom: rem(80);
    .introtext {
        margin: 0 auto rem(24) auto;
    }
    picture,
    img {
        display: block;
        max-width: 100%;
        width: 100%;
    }
    .speaker {
        &-name {
            font-size: rem(30);
            color: $white;
            text-transform: uppercase;
            margin-bottom: rem(10);
        }
        &-place {
            font-size: rem(18);
            margin-bottom: rem(40);
            color: rgb(220, 220, 220);
            letter-spacing: 0.05em;
        }
        &-title {
            font-size: rem(15);
            font-family: 'Quicksand', sans-serif;
            text-transform: uppercase;
        }
        &-grid__item {
            margin-top: rem(15);
            position: relative;
            a {
                &:hover {
                    &::before {
                        content: '';
                        height: 100px;
                        width: 100px;
                        background-image: url('../img/icon_video_play.svg');
                        background-repeat: no-repeat;
                        opacity: .7;
                        position: absolute;
                        top: calc(25% - 50px);
                        left: calc(50% - 50px);
                        display: block;
                        z-index: 1;
                    }
                    ~.speaker-grid__item__content {
                        transition: filter 0.4s;
                        filter: brightness(110%) saturate(1.5) hue-rotate(33deg);
                    }
                    ~picture {
                        filter: brightness(80%);
                        transition: filter 0.5s;
                        will-change: filter;
                    }
                }
            }
            &__content {
                text-align: center;
                padding: rem(30) rem(15);
                position: relative;
                height: rem(290);
                background-image: linear-gradient($background_gradient-green, $background_gradient-blue);
                @include media-breakpoint-up(md) {
                    height: rem(240);
                }
            }
        }
    }
    @include media-breakpoint-up(md) {
        margin-bottom: rem(160);
        .introtext {
            max-width: 80%;
        }
    }
}