.container {
    &__header {
        position: relative;
        display: block;
        text-align: center;
        padding-top: rem(50);
        padding-bottom: rem(50);
        h2 {
            margin: 0;
            position: relative;
            z-index: 1;
        }
    }
    &__content {
        position: relative;
        padding: rem(20);
    }
}

@include media-breakpoint-up(md) {
    .container {
        &__header {
            padding-bottom: rem(60);
        }
        &__content {
            padding: rem(50);
        }
    }
}
.header-slime {
    position: absolute;
    height: 130%;
    width: 260px;
    top: -20%;
    left: calc(50% - 130px);
    filter: url('#fancy-goo');
}
.slime {
    position: absolute;
    left: 0;
    width: 100%;
    &-1 {
        clip-path: circle(30% at 60% 50%);
        animation: slime-wobble-1 10s linear infinite backwards;
    }
    &-2 {
        clip-path: circle(10% at 40% 20%);
        animation: slime-wobble-1 7s linear infinite alternate;
    }
    &-3 {
        clip-path: circle(20% at 50% 50%);
        animation: slime-wobble-2 13s 5s linear infinite alternate;
    }
}
@keyframes slime-wobble-1 {
    30% {
        clip-path: circle(25% at 80% 40%);
    }
    70% {
        clip-path: circle(30% at 35% 25%);
    }
}

@keyframes slime-wobble-2 {
    20% {
        clip-path: circle(40% at 30% 60%);
    }
    50% {
        clip-path: circle(20% at 55% 75%);
    }
}
.filter-slime {
    position: fixed;
    left: -100vw;
}
