.schedule {
    padding-bottom: rem(40);
    margin-bottom: rem(80);
    &-block {
        align-items: center;
        margin-bottom: rem(16);
        .time {
            text-align: right;
        }
        .speaker {
            color: $primary;
        }
        @include media-breakpoint-up(sm) {
            padding: rem(16) rem(40);
        }
        @include media-breakpoint-up(md) {
            margin-bottom: rem(32);
            padding: 0 rem(120);
            .time {
                font-size: rem(56);
            }
        }
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: rem(200);
        &-block {
            padding: 0 rem(120);
            .time {
                font-size: rem(72);
            }
        }
    }
}