body {
    color: $white;
    font-family: 'Quicksand', sans-serif;
    padding-top: rem(50);
    @include media-breakpoint-up(md) {
        padding-top: rem(100);
    }
}
ul {
    margin: 0;
    padding-left: 0;
    li {
        list-style: none;
    }
}

a {
    text-decoration: none;
}
img {
    max-width: 100%;
    height: auto;
    display: block;
}

.anchor {
    transform: translateY(rem(-64));
    @include media-breakpoint-up(md) {
        transform: translateY(rem(-120));
    }
}
