.statistics {
    text-align: center;
    margin-bottom: rem(80);
    padding-bottom: rem(48);
    @include media-breakpoint-up(md) {
        padding-bottom: rem(56);
        margin-bottom: rem(160);
    }
    .numbers {
        .number--big {
            font-size: rem(100);
            margin-bottom: 0;
            @include media-breakpoint-up(md) {
                margin: rem(20) auto;
                font-size: rem(150);
            }
        }
        h3 {
            text-transform: uppercase;
            margin: 0 auto;
            font-size: rem(30);
            color: $primary;
        }
    }
}
