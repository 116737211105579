.location {
    margin-bottom: rem(80);
    img {
        width: 100%;
    }
    .container__content {
        h4,
        h3 {
            font-size: rem(40);
            margin: 0;
        }
        p {
            font-size: rem(20);
            margin: 0;
        }
    }
    &__map {
        width: 90%;
        margin: 0 auto;
        display: block;
        padding-bottom: rem(48);
        .btn {
            width: 100%;
            margin: 0 auto rem(32) auto;
        }
        img {
            display: none;
        }
        &-adress {
            border-bottom: 3px solid white;
            padding-bottom: rem(34);
            p {
                span {
                    color: $primary;
                }
            }
        }
        &-description {
            padding-top: rem(34);
        }
    }
    @include media-breakpoint-down(sm) {
        max-width: none;
        padding: 0;
    }
    @include media-breakpoint-up(md) {
        margin-bottom: rem(160);
        .container__content {
            padding: rem(60) rem(50);
            h4,
            h3 {
                font-size: rem(48);
            }
        }
        &__map {
            .btn {
                width: 50%;
                display: block;
                margin: rem(30) auto rem(40) auto;
            }
            &-adress {
                border-bottom: 0;
                border-right: 1px solid white;
                padding-right: rem(34);
                padding-bottom: 0;
            }
            &-description {
                padding-left: rem(34);
                padding-top: 0;
            }
        }
    }
}
