.cta-teaser {
    margin-bottom: rem(80);
    margin-top: rem(80);
    &--content {
        padding-bottom: rem(64);
        max-width: rem(800);
        margin: 0 auto;
        p {
            margin-bottom: rem(40);
        }
    }
    @include media-breakpoint-up(md) {
        margin-bottom: rem(120);
    }
}