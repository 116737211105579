h1,
.fontsize--h1,
h2,
.fontsize--h2,
h3,
.fontsize--h3,
h4,
.fontsize--h4,
h5,
.fontsize--h5,
h6,
.fontsize--h6 {
    color: $white;
    font-family: "BenchNine", sans-serif;
    line-height: 1.5;
}
p,
.fontsize--p {
    color: $white;
    font-family: "Quicksand", sans-serif;
    line-height: 1.5;
    font-weight: 300;
    font-size: rem(16);
    letter-spacing: .02em;
    strong {
        font-weight: 500;
    }
}

h1,
.fontsize--h1 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: rem(35);
}

h2,
.fontsize--h2 {
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    font-size: rem(42);
}
h3,
.fontsize--h3 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: rem(32);
}
h4,
.fontsize--h4 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: rem(28);
}
h5,
.fontsize--h5 {
    font-weight: 400;
    font-size: rem(20);
    font-family: 'Quicksand', sans-serif;
}

a {
    &:hover {
        text-decoration: none;
        color: $white;
    }
}

@include media-breakpoint-up(md) {
    p,
    .fontsize--p {
        font-size: rem(18);
    }

    h1,
    .fontsize--h1 {
        font-size: rem(35);
    }

    h2,
    .fontsize--h2 {
        font-size: rem(53);
    }
    h3,
    .fontsize--h3 {
        font-size: rem(36);
    }
    h4,
    .fontsize--h4 {
        font-size: rem(30);
    }
    h5,
    .fontsize--h5 {
        font-size: rem(24);
    }
}

@supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
    body {
        -webkit-font-smoothing: subpixel-antialiased;
    }
    p,
    .fontsize--p {
        font-weight: 400;
    }
    h3,
    .fontsize--h3 {
        font-weight: 400;
    }
}

/* quicksand-300 - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/quicksand-v7-latin-300.eot');
    src:
        local('Quicksand Light'),
        local('Quicksand-Light'),
        url('../fonts/quicksand-v7-latin-300.eot?#iefix') format('embedded-opentype'),
 
        url('../fonts/quicksand-v7-latin-300.woff2') format('woff2'),
 
        url('../fonts/quicksand-v7-latin-300.woff') format('woff'),
 
        url('../fonts/quicksand-v7-latin-300.ttf') format('truetype'),
 
        url('../fonts/quicksand-v7-latin-300.svg#Quicksand') format('svg');
}
/* quicksand-regular - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/quicksand-v7-latin-regular.eot');
    src:
        local('Quicksand Regular'),
        local('Quicksand-Regular'),
        url('../fonts/quicksand-v7-latin-regular.eot?#iefix') format('embedded-opentype'),
 
        url('../fonts/quicksand-v7-latin-regular.woff2') format('woff2'),
        url('../fonts/quicksand-v7-latin-regular.woff') format('woff'),
        url('../fonts/quicksand-v7-latin-regular.ttf') format('truetype'),
        url('../fonts/quicksand-v7-latin-regular.svg#Quicksand') format('svg');
}
/* quicksand-500 - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/quicksand-v7-latin-500.eot');
    src:
        local('Quicksand Medium'),
        local('Quicksand-Medium'),
        url('../fonts/quicksand-v7-latin-500.eot?#iefix') format('embedded-opentype'),
        url('../fonts/quicksand-v7-latin-500.woff2') format('woff2'),
        url('../fonts/quicksand-v7-latin-500.woff') format('woff'),
        url('../fonts/quicksand-v7-latin-500.ttf') format('truetype'),
        url('../fonts/quicksand-v7-latin-500.svg#Quicksand') format('svg');
}
/* quicksand-700 - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/quicksand-v7-latin-700.eot');
    src:
        local('Quicksand Bold'),
        local('Quicksand-Bold'),
        url('../fonts/quicksand-v7-latin-700.eot?#iefix') format('embedded-opentype'),
        url('../fonts/quicksand-v7-latin-700.woff2') format('woff2'),
        url('../fonts/quicksand-v7-latin-700.woff') format('woff'),
 
        url('../fonts/quicksand-v7-latin-700.ttf') format('truetype'),
        url('../fonts/quicksand-v7-latin-700.svg#Quicksand') format('svg');
}

/* benchnine-300 - latin */
@font-face {
    font-family: 'BenchNine';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/benchnine-v6-latin-300.eot');
    src:
        local('BenchNine Light'),
        local('BenchNine-Light'),
        url('../fonts/benchnine-v6-latin-300.eot?#iefix') format('embedded-opentype'),
        url('../fonts/benchnine-v6-latin-300.woff') format('woff'),
 
        url('../fonts/benchnine-v6-latin-300.ttf') format('truetype'),
 
        url('../fonts/benchnine-v6-latin-300.svg#BenchNine') format('svg');
}
/* benchnine-regular - latin */
@font-face {
    font-family: 'BenchNine';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/benchnine-v6-latin-regular.eot');
    src:
        local('BenchNine Regular'),
        local('BenchNine-Regular'),
        url('../fonts/benchnine-v6-latin-regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/benchnine-v6-latin-regular.woff2') format('woff2'),
        url('../fonts/benchnine-v6-latin-regular.woff') format('woff'),
        url('../fonts/benchnine-v6-latin-regular.ttf') format('truetype'),
        url('../fonts/benchnine-v6-latin-regular.svg#BenchNine') format('svg');
}
/* benchnine-700 - latin */
@font-face {
    font-family: 'BenchNine';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/benchnine-v6-latin-700.eot');
    src:
        local('BenchNine Bold'),
        local('BenchNine-Bold'),
        url('../fonts/benchnine-v6-latin-700.eot?#iefix') format('embedded-opentype'),
        url('../fonts/benchnine-v6-latin-700.woff2') format('woff2'),
        url('../fonts/benchnine-v6-latin-700.woff') format('woff'),
        url('../fonts/benchnine-v6-latin-700.ttf') format('truetype'),
        url('../fonts/benchnine-v6-latin-700.svg#BenchNine') format('svg');
}