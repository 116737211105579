.sponsor-goals {
    padding: rem(64) rem(32);
    margin-bottom: rem(64);
    &--item {
        align-items: center;
        &:not(:last-of-type) {
            margin-bottom: rem(40);
        }
    }
    @include media-breakpoint-up(md) {
        margin-bottom: rem(180);
        padding: rem(64) rem(40);
    }
    @include media-breakpoint-up(lg) {
        padding: rem(80);
        &--item {
            &:not(:last-of-type) {
                margin-bottom: rem(80);
            }
        }
    }
}