.picture-text {
    margin-bottom: rem(80);
    margin-top: rem(80);
    padding-bottom: rem(32);
    img {
        margin: 0;
        width: 100%;
    }
    .picture-text__text {
        padding: rem(32);
    }
    @include media-breakpoint-up(md) {
        margin-bottom: rem(160);
        padding-bottom: rem(56);
    }
    @include media-breakpoint-up(lg) {
        .picture-text__text {
            padding: 0 rem(56);
        }
    }
}
