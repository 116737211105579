.keyvisual {
    min-height: 85vh;
    text-align: center;
    position: relative;
    margin-bottom: rem(80);
    margin-top: rem(-50);
    .logo {
        width: 50vh;
        margin: 0 auto;
        display: block;
        position: relative;
        padding-bottom: rem(0);
        padding-top: rem(60);
    }
    .info_container {
        position: relative;
        width: 90%;
        margin: rem(40) auto 0 auto;
        padding: rem(24) rem(16);
        background-color: $black-transparent-t8;
        .infos {
            display: flex;
            justify-content: center;
            flex-direction: column;
            .date,
            .place {
                width: auto;
                text-align: center;
                padding: 0;
                p {
                    font-size: rem(32);
                    font-family: "BenchNine", sans-serif;
                    text-transform: uppercase;
                    line-height: 1.2;
                    margin-bottom: rem(30);
                    font-weight: bold;
                    span {
                        font-size: rem(26);
                        font-weight: normal;
                        font-family: 'Quicksand', sans-serif;
                    }
                }
            }
        }
        .save_the_date {
            background-color: $secondary;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            transform: rotate(18deg);
            top: rem(-192);
            width: rem(100);
            height: rem(100);
            left: auto;
            right: 0;
            animation: save-the-date-pulse 2s ease-in-out infinite;
            p {
                max-width: 80%;
                text-align: center;
                color: $primary;
                font-family: 'BenchNine', sans-serif;
                text-transform: uppercase;
                font-size: rem(24);
                font-weight: bold;
                margin: 0 auto;
            }
        }
    }
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../img/keyvisual/oil.png');
        background-repeat: no-repeat;
        background-size: 200%;
        z-index: -1;
    }
}

@include media-breakpoint-up(md) {
    .keyvisual {
        padding-top: rem(75);
        position: relative;
        margin-bottom: rem(200);
        margin-top: rem(-100);
        .container {
            margin-top: 5vh;
        }
        .logo {
            width: 70vh;
            max-width: rem(500);
            padding-bottom: 0;
            padding-top: 0;
        }
        .info_container {
            margin: 5vh auto 0;
            max-width: rem(700);
            text-align: center;
            padding: rem(56) rem(20);
            .infos {
                flex-direction: row;
                .date,
                .place {
                    p {
                        font-size: rem(48);
                        margin-bottom: 0;
                        span {
                            font-size: rem(38);
                        }
                    }
                }
                .place {
                    padding-left: rem(48);
                    text-align: left;
                }
                .date {
                    text-align: right;
                }
            }
            .btn {
                min-width: 40%;
            }
            p {
                color: $white;
                text-align: justify;
                font-size: rem(45);
                margin: 0 auto;
                font-weight: 400;
            }
            .save_the_date {
                width: rem(150);
                height: rem(150);
                display: flex;
                top: rem(-256);
                margin-right: -5vw;
                left: auto;
                right: 0;
                p {
                    max-width: 80%;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: rem(36);
                    margin: 0 auto;
                }
            }
        }
        .keyvisual-ticketsale {
            max-width: rem(400);
            transition: transform 0.3s;
            margin-top: rem(48);
            &:after {
                right: rem(40);
                height: 2.2em;
                width: 3em;
                animation: arrow-pulse 1.5s ease-in-out infinite;
            }
            &:hover {
                transform: scale(1.02);
            }
            p {
                font-size: rem(32);
                color: $secondary;
            }
        }
        &:before {
            background-size: cover;
            top: 0;
            height: calc(100vw / 1.3);
        }
    }
}

@include media-breakpoint-up(lg) {
    .keyvisual {
        .infos {
            .save_the_date {
                width: rem(210);
                height: rem(210);
                top: rem(-250);
                margin-right: -10vw;
                p {
                    font-size: rem(48);
                }
            }
        }
    }
}

@keyframes arrow-pulse {
    40% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes save-the-date-pulse {
    55% {
        transform: scale(1.1) rotate(18deg);
    }
}
