.impressions {
    margin-bottom: rem(80);
    .gallery {
        background: linear-gradient($secondary, $secondary);
        background-repeat: no-repeat;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .impressions--quote {
            padding: rem(30) rem(20);
            display: flex;
            flex-direction: column;
            justify-content: center;
            .author {
                span {
                    color: $primary;
                }
            }
        }
    }
    @include media-breakpoint-up(md) {
        margin-bottom: rem(180);
        .gallery {
            background-size: 99.899%; // to avoid background being wider than content, bc sum of cols is less than 100%
            .impressions--quote {
                padding: rem(40);
            }
        }
    }
}