nav {
    background-color: $black-transparent-t8;
    ul {
        li {
            text-align: center;
            a {
                font-weight: 300;
                color: $white;
                display: block;
                font-size: rem(22);
                padding: rem(10);
                text-transform: uppercase;
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    nav {
        height: 100vh;
        opacity: 0;
        transition: opacity 0.2s;
        pointer-events: none;
        ul {
            li {
                margin: 0;
                padding-top: rem(10);
                transition: transform 0.4s cubic-bezier(.29, .6, .4, .99), opacity 0.2s;
                opacity: 0;
                &.home {
                    display: none;
                }
                &:nth-of-type(1) {
                    transform: translateY(-50%) scaleY(0);
                }
                &:nth-of-type(2) {
                    transform: translateY(-150%) scaleY(0);
                }
                &:nth-of-type(3) {
                    transform: translateY(-250%) scaleY(0);
                }
                &:nth-of-type(4) {
                    transform: translateY(-350%) scaleY(0);
                }
                &:nth-of-type(5) {
                    transform: translateY(-450%) scaleY(0);
                }
                &:nth-of-type(6) {
                    transform: translateY(-550%) scaleY(0);
                }
                &:nth-of-type(7) {
                    transform: translateY(-650%) scaleY(0);
                }
                &:nth-of-type(8) {
                    transform: translateY(-750%) scaleY(0);
                }
                &:nth-of-type(9) {
                    transform: translateY(-850%) scaleY(0);
                }
                &.active a {
                    color: $primary;
                    border-top: rem(3) solid $primary;
                    border-bottom: rem(3) solid $primary;
                }
                a {
                    &.tickets {
                        background-color: $secondary;
                        border-radius: rem(4);
                        color: $primary;
                        margin: 0 auto;
                        width: 50%;
                    }
                }
            }
        }
        &.active {
            opacity: 1;
            transition: opacity 0.2s;
            pointer-events: inherit;
            ul {
                li {
                    transition: transform 0.4s cubic-bezier(.29, .6, .4, .99), opacity 0.2s;
                    transform: translateY(0%) scaleY(1);
                    opacity: 1;
                }
            }
        }
    }
    #hamburger {
        svg {
            rect {
                &:nth-of-type(1) {
                    transform: translateY(0) rotate(0deg);
                    transform-origin: rem(22) rem(2);
                    transition: transform 0.2s ease-out;
                }
                &:nth-of-type(2) {
                    transform: scaleX(1);
                    transform-origin: rem(22) rem(15);
                    transition: transform 0.1s ease-out;
                }
                &:nth-of-type(3) {
                    transform: translateY(0) rotate(0deg);
                    transform-origin: rem(22) rem(28);
                    transition: transform 0.2s ease-out;
                }
            }
        }
        &.active {
            rect {
                &:nth-of-type(1) {
                    transform: translateY(#{rem(13)}) rotate(30deg);
                    transform-origin: rem(22) rem(2);
                    transition: transform 0.2s ease-out;
                }
                &:nth-of-type(2) {
                    transform: scaleX(0);
                    transform-origin: rem(22) rem(15);
                    transition: transform 0.1s ease-out;
                }
                &:nth-of-type(3) {
                    transform: translateY(#{rem(-13)}) rotate(-30deg);
                    transform-origin: rem(22) rem(28);
                    transition: transform 0.2s ease-out;
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    nav {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        padding: 0 rem(20);
        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: relative;
            li {
                margin-left: rem(20);
                position: relative;
                &:first-of-type {
                    margin-left: 0;
                }
                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    bottom: rem(-8);
                    left: rem(20);
                    right: rem(20);
                    height: rem(5);
                    background-color: transparent;
                    transition: transform .3s, opacity .3s;
                    transform: scale(0, .5);
                }
                &.active,
                &:hover {
                    a {
                        color: $white;
                    }
                    &:after {
                        background-color: $primary;
                        transition: transform .3s, opacity .3s;
                        transform: scale(1, 1);
                    }
                }
                &.li-ticket,
                &.language,
                &.home {
                    &.active,
                    &:hover {
                        &:after {
                            background-color: transparent;
                        }
                    }
                }
                &.language {
                    position: absolute;
                    right: 0;
                    &.active,
                    &:hover {
                        a {
                            color: $primary;
                        }
                    }
                }
                &.home {
                    position: absolute;
                    left: 0;
                    img {
                        width: rem(48);
                    }
                }
                a {
                    font-size: rem(16);
                    &.tickets {
                        background-color: transparent;
                        border: rem(2) $primary solid;
                        color: $white;
                        transition: all 0.15s ease-in-out;
                        &:hover {
                            background-color: $primary;
                            color: $secondary;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    nav {
        ul {
            li {
                a {
                    font-size: rem(18);
                }
            }
        }
    }
}
