.btn {
    display: inline-flex;
    align-items: center;

    &-block {
        width: 100%;
        justify-content: center;
    }

    > svg:first-child:not(:last-child) {
        margin-right: rem(8);

        &[size="16"] {
            margin-right: rem(4);
        }
    }

    > svg:last-child:not(:first-child) {
        margin-left: rem(8);

        &[size="16"] {
            margin-left: rem(4);
        }
    }
}
