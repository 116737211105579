.blog-post {
    padding: rem(32) rem(24);
    margin: rem(80) auto;
    img {
        width: 100%;
        margin-bottom: rem(32);
    }
    picture {
        &:last-child {
            img {
                margin-bottom: 0;
            }
        }
    }
    @include media-breakpoint-up(md) {
        padding: rem(64) rem(48);
        margin: rem(160) auto;
        img {
            margin-bottom: rem(48);
        }
        picture {
            &:last-child {
                img {
                    margin-bottom: 0;
                }
            }
        }
    }
}
