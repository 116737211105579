footer {
    margin: 0 auto;
    text-align: center;
    height: auto;
    width: 100%;
    margin-top: rem(50);
    margin-bottom: rem(64);
    .footer__links {
        .footer__links__social {
            position: relative;
            margin: 0 auto;
            a {
                width: rem(55);
                margin: rem(40) rem(8);
                display: inline-block;
                filter: saturate(0);
                img {
                    width: 100%;
                    text-align: center;
                }
                &:hover,
                &:focus {
                    filter: saturate(1);
                }
            }
        }
        .footer__text {
            width: 80%;
            margin: auto;
            padding-top: rem(20);
            h4 {
                margin-top: rem(10);
            }
        }
    }
    ul {
        li {
            font-weight: 300;
            a {
                color: $white;
                padding: rem(20) 0;
                display: block;
                letter-spacing: .02em;
                text-transform: uppercase;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    footer {
        text-align: left;
        margin-bottom: 0;
        .footer__links {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 90%;
            margin: 0 auto;
            padding: rem(70) 0;
            .footer__text {
                width: 40%;
                margin: 0 rem(60) 0 0;
                p {
                    font-size: rem(20);
                }
                h4 {
                    margin-top: rem(10);
                }
            }
            .footer_links__contact {
                display: flex;
                flex-direction: column;
                align-items: center;
                .footer__links__social {
                    margin: rem(20) 0;
                }
            }
        }
        ul {
            display: flex;
            justify-content: center;
            padding-bottom: rem(50);
            li {
                margin-left: rem(20);
                &:first-of-type {
                    margin-left: 0;
                }
                a {
                    padding: rem(20);
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }
}
