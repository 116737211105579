.btn {
    border-radius: 0;
    padding: rem(12) rem(40);
    font-family: "BenchNine", sans-serif;
    font-size: rem(20);
    color: $primary;
    border: none;
    text-transform: uppercase;
    transition: all .3s ease-in;
    justify-content: center;
    &:hover {
        color: $primary;
    }
    &-primary {
        background-color: $secondary;
        &:hover {
            color: $secondary;
        }
    }
    @include media-breakpoint-up(md) {
        font-size: rem(24);
        padding: rem(14) rem(48);
    }
}
