body {
    background-image: url("../img/background/coal.jpg");
    background-position: top center;
    background-repeat: repeat-y;
    background-attachment: fixed;
    background-size: contain;
    background-color: $black;
    overflow-x: hidden;
    overflow-y: auto;
}

@include media-breakpoint-up(md) {
    body {
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.background--transparent-black {
    background: $background-transparent-t9;
}
.background--gradient {
    background: linear-gradient(to right, $background_gradient-green, $background_gradient-blue);
}
