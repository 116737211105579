.quote {
    margin-bottom: rem(80);
    &-item {
        position: relative;
        padding: rem(70) rem(50) rem(50) rem(50);
        height: 100%;
        &:before {
            content: '';
            display: block;
            position: absolute;
            height: rem(50);
            width: rem(50);
            top: rem(-20);
            left: calc(20%);
            background-image: url('../img/deco/quote.svg');
            background-size: contain;
        }
        .quote {
            font-size: rem(36);
            font-family: 'BenchNine', sans-serif;
            text-transform: uppercase;
            margin-bottom: rem(20);
        }
        .author {
            font-size: rem(20);
            span {
                color: $primary;
            }
        }
    }
    @include media-breakpoint-up(md) {
        margin-bottom: rem(200);
    }
}